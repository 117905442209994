/* eslint no-unused-vars: "off" */
export default function slidePrev(speed = this.params.speed, runCallbacks = true, internal) {
  const swiper = this;
  const { params, animating, snapGrid, slidesGrid, rtlTranslate, enabled } = swiper;
  if (!enabled) return swiper;

  if (params.loop) {
    if (animating &amp;&amp; params.loopPreventsSlide) return false;
    swiper.loopFix();
    // eslint-disable-next-line
    swiper._clientLeft = swiper.$wrapperEl[0].clientLeft;
  }
  const translate = rtlTranslate ? swiper.translate : -swiper.translate;

  function normalize(val) {
    if (val &lt; 0) return -Math.floor(Math.abs(val));
    return Math.floor(val);
  }
  const normalizedTranslate = normalize(translate);
  const normalizedSnapGrid = snapGrid.map((val) =&gt; normalize(val));

  let prevSnap = snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];
  if (typeof prevSnap === 'undefined' &amp;&amp; params.cssMode) {
    let prevSnapIndex;
    snapGrid.forEach((snap, snapIndex) =&gt; {
      if (normalizedTranslate &gt;= snap) {
        // prevSnap = snap;
        prevSnapIndex = snapIndex;
      }
    });
    if (typeof prevSnapIndex !== 'undefined') {
      prevSnap = snapGrid[prevSnapIndex &gt; 0 ? prevSnapIndex - 1 : prevSnapIndex];
    }
  }
  let prevIndex = 0;
  if (typeof prevSnap !== 'undefined') {
    prevIndex = slidesGrid.indexOf(prevSnap);
    if (prevIndex &lt; 0) prevIndex = swiper.activeIndex - 1;
    if (
      params.slidesPerView === 'auto' &amp;&amp;
      params.slidesPerGroup === 1 &amp;&amp;
      params.slidesPerGroupAuto
    ) {
      prevIndex = prevIndex - swiper.slidesPerViewDynamic('previous', true) + 1;
      prevIndex = Math.max(prevIndex, 0);
    }
  }
  if (params.rewind &amp;&amp; swiper.isBeginning) {
    const lastIndex =
      swiper.params.virtual &amp;&amp; swiper.params.virtual.enabled &amp;&amp; swiper.virtual
        ? swiper.virtual.slides.length - 1
        : swiper.slides.length - 1;
    return swiper.slideTo(lastIndex, speed, runCallbacks, internal);
  }
  return swiper.slideTo(prevIndex, speed, runCallbacks, internal);
}
