import $ from '../../shared/dom.js';

export default function updateSlidesProgress(translate = (this &amp;&amp; this.translate) || 0) {
  const swiper = this;
  const params = swiper.params;

  const { slides, rtlTranslate: rtl, snapGrid } = swiper;

  if (slides.length === 0) return;
  if (typeof slides[0].swiperSlideOffset === 'undefined') swiper.updateSlidesOffset();

  let offsetCenter = -translate;
  if (rtl) offsetCenter = translate;

  // Visible Slides
  slides.removeClass(params.slideVisibleClass);

  swiper.visibleSlidesIndexes = [];
  swiper.visibleSlides = [];

  for (let i = 0; i &lt; slides.length; i += 1) {
    const slide = slides[i];
    let slideOffset = slide.swiperSlideOffset;
    if (params.cssMode &amp;&amp; params.centeredSlides) {
      slideOffset -= slides[0].swiperSlideOffset;
    }

    const slideProgress =
      (offsetCenter + (params.centeredSlides ? swiper.minTranslate() : 0) - slideOffset) /
      (slide.swiperSlideSize + params.spaceBetween);
    const originalSlideProgress =
      (offsetCenter -
        snapGrid[0] +
        (params.centeredSlides ? swiper.minTranslate() : 0) -
        slideOffset) /
      (slide.swiperSlideSize + params.spaceBetween);
    const slideBefore = -(offsetCenter - slideOffset);
    const slideAfter = slideBefore + swiper.slidesSizesGrid[i];
    const isVisible =
      (slideBefore &gt;= 0 &amp;&amp; slideBefore &lt; swiper.size - 1) ||
      (slideAfter &gt; 1 &amp;&amp; slideAfter &lt;= swiper.size) ||
      (slideBefore &lt;= 0 &amp;&amp; slideAfter &gt;= swiper.size);
    if (isVisible) {
      swiper.visibleSlides.push(slide);
      swiper.visibleSlidesIndexes.push(i);
      slides.eq(i).addClass(params.slideVisibleClass);
    }
    slide.progress = rtl ? -slideProgress : slideProgress;
    slide.originalProgress = rtl ? -originalSlideProgress : originalSlideProgress;
  }
  swiper.visibleSlides = $(swiper.visibleSlides);
}
